export function genLang(text) {
    const lang = localStorage.getItem("i18nextLng")?.split('-')[0] ?? 'fr';
    // const parsedLang = lang === 
    try {
      if (typeof text === "object") {
        return text[lang];
      }
    } catch (e) {
      console.error(e);
      console.error("TRAD KEY NOT FOUND: ", text);
    }
  }
  